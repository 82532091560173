import { HttpClient, HttpEvent, HttpHeaders, HttpRequest, } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UploadImageService {

  private _HttpClient = inject(HttpClient);

  // this func will get the url that we are going to use to upload image
  getUploadImageUrl(selectedFile: File, folderName: string): Observable<any> {
    return this._HttpClient.get(`${environment.endPointUrl}/v1/s3/signed-url?contentType=${selectedFile.type}&filename=${selectedFile.name}&folder=${folderName}`);
  }

  // this will upload the file to the url made for uploading
  uploadImage(selectedFile: File, url: string): Observable<HttpEvent<any>> {
    const request = new HttpRequest('PUT', url, selectedFile, {
      reportProgress: true,
      headers: new HttpHeaders({
        'Content-Type': selectedFile.type,
      }),
    });

    return this._HttpClient.request(request);
  }

  // start helper functions

    // this func will get the image url from complicated url given from s3 image API endpoint
    extractImageUrl(url: string): string | null {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp'];

      for (const extension of imageExtensions) {
        const indexOfExtension = url.toLowerCase().indexOf(extension);

        if (indexOfExtension !== -1) {
          return url.substring(0, indexOfExtension + extension.length);
        }
      }

      return null; // If none of the image extensions are found in the URL
    }

    // this function is used to get the file size human readable only
    convertBytesToReadable(bytes: number): string {
      const units: string[] = ["B", "KB", "MB", "GB", "TB", "PB"];
      let unitIndex: number = 0;

      while (bytes >= 1024) {
        bytes /= 1024;
        unitIndex += 1;
      }

      return `${bytes.toFixed(2)} ${units[unitIndex]}`;
    }

  // end helper functions
}
